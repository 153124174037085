import React, { Component } from 'react';
import ReactGA from 'react-ga';

import AppRouter from './Router';

import './styles/reset.css';
import './styles/global.scss';

ReactGA.initialize('UA-132333520-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <AppRouter />
    );
  }
}

export default App;