import React, { Component } from 'react';

import Home from './Home';

import '../styles/loading.scss';

class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false
    }
  }

  componentDidMount() {
    // Check to see if we're on desktop and if we have shown loading screen recently
    if (this.isDesktop() && this.checkLoadCookie() === false) {
        this.setState({ showLoading: true });
        setTimeout(() => {
          document.cookie = "loadedEH=true";
          this.setState({ showLoading: false });
        }, 8400);
    }
  }

  checkLoadCookie = () => {
    if (document.cookie.split(';').filter((item) => item.includes('loadedEH=')).length) {
      return true;
    }
    return false;
  };

  isDesktop = () => {
    return window.innerWidth >= 900 ? true : false;
  }

  render() {
    return (
      <div>
       { ((this.state.showLoading)) ? (
        <div id="loading-wrapper">
          <h1 className="name">e<span className="letters">mily habashy</span></h1>
          <div className="animated-words">
            <span>copywriter</span>
            <span>word sommelier</span>
            <span>letter lobbyist</span>
            <span>vernacular matchmaker</span>
          </div>
        </div>
       ) : (
        <Home catify={this.props.catify} />
       )}
      </div>
    )
  }
};

export default Loading;