import React, { Component } from 'react';

import Footer from './Footer';

import '../styles/video.scss';

class VideoLayout extends Component {
  renderVimeoVideos = (videos) => {
    const videoEmbeds = videos.map((video, index) => (
      <div key={index} className="video-wrapper">
        <iframe title="vimeo" className="video" src={video} allowFullScreen></iframe>
      </div>
    ));

    return videoEmbeds;
  }

  renderYoutubeVideos = (videos) => {
    const videoEmbeds = videos.map((video, index) => (
      <div key={index} className="video-wrapper">
        <iframe title="Cats" className="video" src={video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    ));

    return videoEmbeds;
  }

  // Catify
  renderCats = () => {
    const location = this.props.location.pathname.toLowerCase().substr(1);

    const content = {
      thinx: {
        header: "meow",
        role: "Meow meow meow meow",
        description: "You hoo-man. We cat.",
        videos: [
          "https://www.youtube.com/embed/xSE9Qk9wkig",
          "https://www.youtube.com/embed/jpsGLsaZKS0",
          "https://www.youtube.com/embed/IZ58M1sAaWY",
        ]
      },
      wordpress: {
        header: "purr",
        role: "Meow",
        description: "Some videos fur your enjoyment.",
        videos: [
          "https://www.youtube.com/embed/G4Sn91t1V4g",
          "https://www.youtube.com/embed/UjaAxUO6-Uw",
        ]
      },
      zola: {
        header: "meowage",
        role: "Meow meow meow",
        description: "He cat. She cat. Together furever. 😽😽",
        videos: [
          "https://www.youtube.com/embed/WZilANTmpC0",
          "https://www.youtube.com/embed/XfKJeQbtRCA",
          "https://www.youtube.com/embed/QYkJ0M1bUIU"
        ]
      },
      primary: {
        header: "meeeow!",
        role: "Meowin’ meow meowing meow",
        description: "Cats make such beautiful meowsic.",
        videos: [
          "https://www.youtube.com/embed/lAIGb1lfpBw",
          "https://www.youtube.com/embed/4rrXR6n0RTY",
          "https://www.youtube.com/embed/8IHLQxOghUQ"
        ]
      },
      keeps: {
        header: "meow meow",
        role: "purrrrrrr",
        description: "He see box. He sit in box.",
        videos: [
          "https://www.youtube.com/embed/2XID_W4neJo",
          "https://www.youtube.com/embed/PaKLB71QE4k"
        ]
      },
      cove: {
        header: "meowgraine",
        role: 'Purr purr purr',
        description: "Kitty ASMR is  best ASMR.",
        videos: [
          "https://www.youtube.com/embed/9nv6pR2Xgdo",
          "https://www.youtube.com/embed/gV7TM8vuekA",
          "https://www.youtube.com/embed/MxwH9RbQzJ0",
          "https://www.youtube.com/embed/_z9Fwziw0Bs",
        ]
      },
    }

    return (
      <div className="video-page-content">
        <div className="copy">
          <h1 className="header">{content[location].header}</h1>
          <h2 className="role">{content[location].role}</h2>
          <p className="text">{content[location].description}</p>
        </div>

        {!!content[location].videos.length &&
          <div className="videos-wrapper">
            {this.renderYoutubeVideos(content[location].videos)}
          </div>
        }
      </div>
    )
  }

  renderContent = () => {
    const location = this.props.location.pathname.toLowerCase().substr(1);

    const content = {
      thinx: {
        header: 'THINX(BTWN) Launch',
        role: 'Concepting and copywriting',
        description: "A reported 39% of girls skip sports and cancel plans on their periods because they’re afraid of leaks. To launch THINX(BTWN), the first period underwear made for adolescents, we created a campaign to show girls that their periods shouldn’t keep them from what they love.",
        videos: [
          "https://player.vimeo.com/video/305142642?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/305143572?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/305144238?title=0&byline=0&portrait=0",
        ]
      },
      wordpress: {
        header: "Unskippable Pre-roll",
        role: 'Concepting and copywriting',
        description: "When we were asked to make longform pre-roll for Wordpress.com, our challenge was to make something that could beat the skip button. We created two videos, with the goal of empowering small business owners. Both videos performed so well that we were asked to make more.",
        videos: [
          "https://player.vimeo.com/video/305145021?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/305145768?title=0&byline=0&portrait=0",
        ]
      },
      zola: {
        header: "First Comes Love, Then Comes Zola",
        role: 'Concepting and copywriting',
        description: "When couples get engaged, they don’t know where to start. So we created a campaign that speaks directly to newly-engaged millennial couples, showing them that Zola makes wedding planning so easy, even cake toppers can do it.",
        videos: [
          "https://player.vimeo.com/video/305146894?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/305146446?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/305146948?title=0&byline=0&portrait=0",
        ]
      },
      primary: {
        header: "Meet Primary",
        role: 'Concepting, and copywriting',
        description: "Primary is leading a movement in making beautiful and affordable gender neutral kids clothing. For their national TV launch, we put their mission front and center with a three-part campaign that shows what Primary is all about.",
        videos: [
          "https://player.vimeo.com/video/304893744?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/304894417?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/304894882?title=0&byline=0&portrait=0",
        ]
      },
      keeps: {
        header: "Ads That Inspire Action",
        role: "Concepting and copywriting",
        description: "Because hair loss is a touchy subject, many men wait until it’s too late to start treatment. Keeps asked us to help inspire men to take the first step. The result? Ads that highlight moments of truth in a man’s life, both funny and relatable.",
        videos: [
          "https://player.vimeo.com/video/306596982?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/306596996?title=0&byline=0&portrait=0",
        ]
      },
      // Videos need updating
      cove: {
        header: "Atypical Pharma Ads",
        role: 'Concepting and copywriting',
        description: "Migraines make life hard. Cove makes treatment easy. To launch Cove, we tuned into the insight that migraine sufferers live in fear of potential triggers. Forgoing sterile pharma language, we connected with them using soothing tones and empathy, to show that we understand their struggle and can work together to find a solution.",
        videos: [
          "https://player.vimeo.com/video/315115563?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/315116751?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/315117088?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/315117652?title=0&byline=0&portrait=0",
        ]
      },
    };

    return (
      <div className="video-page-content">
        <div className="copy">
          <h1 className="header">{content[location].header}</h1>
          <h2 className="role">{content[location].role}</h2>
          <p className="text">{content[location].description}</p>
        </div>

          { !!content[location].videos.length &&
            <div className="videos-wrapper">
              {this.renderVimeoVideos(content[location].videos)}
            </div>
          }
      </div>
    )
  }

  render() {
    return (
      <div className="video-page-wrapper">
        {(!!this.props.catify) ? (
          this.renderCats()
        ) : (
          this.renderContent()
        )}
        <Footer location={this.props.location} />
      </div>
    );
  }
}

export default VideoLayout;