import React from 'react';

import '../styles/about.scss';
import waving from '../assets/about/waving.png';
import about1 from '../assets/about/about1.jpg';
import about2 from '../assets/about/about2.jpg';

// Cats
import paws from '../assets/about/cats/paws.png';
import cat1 from '../assets/about/cats/cat1.jpg';
import cat2 from '../assets/about/cats/cat2.jpg';

function About(props) {
  return (
    <div className="about-wrapper">
        {(props.catify) ? (
          <div className="intro">
            <img className="waving paws" src={paws} alt="Waving Hand" />
            <h1 className="header">Meow</h1>
            <p className="role">I’m a cat.</p>
          </div>
        ) : (
          <div className="intro">
            <img className="waving" src={waving} alt="Waving Hand" />
            <h1 className="header">I'm Emily.</h1>
            <p className="role">Nice to meet you!</p>
          </div>
        )}

      {(props.catify) ? (
        <div className="section1">
          <img src={cat1} alt="Portrait" />
          <p className="text">
            I’m cute and soft. You take pictures of me and put them on the internet.
            I sit on your keyboard.
            I ruin your furniture.
            I like watching birds outside and plotting their doom.
          </p>
        </div>
        ) : (
        <div className="section1">
          <img src={about1} alt="Portrait" />
          <p className="text">I’m a New York-based creative, fond of sharp pencils and ideas that shine. Words are my weapon of choice, and I use them to tell stories and build worlds for some of today’s biggest brands.</p>
        </div>
      )}

      {(props.catify) ? (
        <div className="section2">
          <img src={cat2} alt="Portrait" />
          <p className="text">
            I will sleep on your favorite sweater and knock every cup off the counter just for fun.<br />
            Then I’ll jump on you while you’re sleeping and cry for food.<br />
            Because I’m a cat.<br />
            Meow.<br />
          </p>
        </div>
      ) : (
        <div className="section2">
          <img src={about2} alt="Portrait" />
          <p className="text">
            I have a <a href="https://www.youtube.com/watch?v=Lrle0x_DHBM" target="_blank" rel="noopener noreferrer">favorite groove song.</a><br />
            I have a <a href="https://www.merriam-webster.com/dictionary/loquacious" target="_blank" rel="noopener noreferrer">favorite word.</a><br />
            I have a <a href="https://www.google.com/search?q=cats+wearing+bows&tbm=isch&tbo=u&source=univ&sa=X&sqi=2&ved=2ahUKEwiQxu6889DfAhViBGMBHZS4B_gQsAR6BAgEEAE&biw=1263&bih=696" target="_blank" rel="noopener noreferrer">favorite Google search.</a><br />
            My hobbies include knitting, bad puns, and dessert.<br />
            Connect with me on <a href="https://www.linkedin.com/in/emilyhabashy/" target="_blank" rel="noopener noreferrer">LinkedIn</a>, <a href="mailto:emilyhabashy@gmail.com">email</a>, <a href="https://twitter.com/emilyemotes" target="_blank" rel="noopener noreferrer">Twitter</a>, or <a href="https://www.instagram.com/letterlikealady/" target="_blank" rel="noopener noreferrer">Instagram</a>.
          </p>
        </div>
      )}
    </div>
  )
};

export default About;