import React, { Component } from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';

import '../styles/home.scss';

// Import images
import thinxImg from '../assets/landing/mobile/thinx.jpg';
import wordpressImg from '../assets/landing/mobile/wordpress.jpg';
import zolaImg from '../assets/landing/mobile/zola.jpg';
import scottevestImg from '../assets/landing/mobile/scottevest.jpg';
import primaryImg from '../assets/landing/mobile/primary.jpg';
import eyeballpartyImg from '../assets/landing/mobile/eyeballparty.jpg';
import keepsImg from '../assets/landing/mobile/keeps.jpg';
import coveImg from '../assets/landing/mobile/cove.jpg';
import letteringImg from '../assets/landing/mobile/lettering.jpg';

// Import gifs
import thinxGif from '../assets/landing/gifs/thinx.gif';
import wordpressGif from '../assets/landing/gifs/wordpress.gif';
import zolaGif from '../assets/landing/gifs/zola.gif';
import scottevestGif from '../assets/landing/gifs/scottevest.gif';
import primaryGif from '../assets/landing/gifs/primary.gif';
import keepsGif from '../assets/landing/gifs/keeps.gif';
import coveGif from '../assets/landing/gifs/cove.gif';
import eyeballpartyGif from '../assets/landing/gifs/eyeballparty.gif';
import letteringGif from '../assets/landing/gifs/lettering.gif';

// Cat gifs
import catGif1 from '../assets/landing/cats/gifs/thinx.gif';
import catGif2 from '../assets/landing/cats/gifs/wordpress.gif';
import catGif3 from '../assets/landing/cats/gifs/zola.gif';
import catGif4 from '../assets/landing/cats/gifs/sev.gif';
import catGif5 from '../assets/landing/cats/gifs/primary.gif';
import catGif6 from '../assets/landing/cats/gifs/keeps.gif';
import catGif7 from '../assets/landing/cats/gifs/eyeballparty.gif';
import catGif8 from '../assets/landing/cats/gifs/lettering.gif';
import catGif9 from '../assets/landing/cats/gifs/cove.gif';

// Cat Mobile Images
import catThinxImg from '../assets/landing/cats/mobile/thinx.jpg';
import catWordpressImg from '../assets/landing/cats/mobile/wordpress.jpg';
import catZolaImg from '../assets/landing/cats/mobile/zola.jpg';
import catScottevestImg from '../assets/landing/cats/mobile/sev.jpg';
import catPrimaryImg from '../assets/landing/cats/mobile/primary.jpg';
import catEyeballpartyImg from '../assets/landing/cats/mobile/eyeballparty.jpg';
import catKeepsImg from '../assets/landing/cats/mobile/keeps.jpg';
import catLetteringImg from '../assets/landing/cats/mobile/lettering.jpg';
import catCoveImg from '../assets/landing/cats/mobile/cove.jpg';

class Home extends Component {
  constructor(props) {
    super(props);

    // TODO: Don't duplicate this
    this.state = {
      activeProject: {
        name: 'thinx',
        img: thinxImg,
        gif: thinxGif,
        text: 'A solution for period anxiety'
      },
    }
  }

  projects = [
    {
      name: 'thinx',
      img: thinxImg,
      gif: thinxGif,
      text: 'A solution for period anxiety'
    },
    {
      name: 'wordpress',
      img: wordpressImg,
      gif: wordpressGif,
      text: 'Longform pre-roll that works'
    },
    {
      name: 'zola',
      img: zolaImg,
      gif: zolaGif,
      text: 'Millennial Wedding Planning 101'
    },
    {
      // Titles are rendered instead of names to account
      // for spacing, capitlization and routing
      name: 'scottevest',
      img: scottevestImg,
      gif: scottevestGif,
      text: 'Calling those who crave adventure'
    },
    {
      name: 'primary',
      img: primaryImg,
      gif: primaryGif,
      text: 'Kids clothing reinvented'
    },
    {
      name: 'keeps',
      img: keepsImg,
      gif: keepsGif,
      text: 'A campaign to inspire action'
    },
    {
      name: 'cove',
      img: coveImg,
      gif: coveGif,
      text: 'A new kind of migraine treatment'
    },
    {
      title: 'eyeball party',
      name: 'eyeballparty',
      img: eyeballpartyImg,
      gif: eyeballpartyGif,
      text: 'Art history with a little extra'
    },
    {
      name: 'lettering',
      img: letteringImg,
      gif: letteringGif,
      text: 'Feisty flourishes'
    },
  ];

  catProjects = [
    {
      title: 'meow',
      name: 'thinx',
      img: catThinxImg,
      gif: catGif1,
      text: 'Hello, hoo-man'
    },
    {
      title: 'purr',
      name: 'wordpress',
      img: catWordpressImg,
      gif: catGif2,
      text: 'Must scratch the thing'
    },
    {
      title: 'meowage',
      name: 'zola',
      img: catZolaImg,
      gif: catGif3,
      text: 'A tale of two kitties'
    },
    {
      title: 'meow',
      name: 'scottevest',
      img: catScottevestImg,
      gif: catGif4,
      text: 'Off the beaten pawth'
    },
    {
      title: 'meeeow!',
      name: 'primary',
      img: catPrimaryImg,
      gif: catGif5,
      text: 'Just kitten around'
    },
    {
      title: 'meow meow',
      name: 'keeps',
      img: catKeepsImg,
      gif: catGif6,
      text: 'If he fits, he sits'
    },
    {
      title: 'meowgraine',
      name: 'cove',
      img: catCoveImg,
      gif: catGif9,
      text: 'From ow to meow'
    },
    {
      title: 'meow?',
      name: 'eyeballparty',
      img: catEyeballpartyImg,
      gif: catGif7,
      text: 'When the catnip kicks in'
    },
    {
      title: 'meowreow',
      name: 'lettering',
      img: catLetteringImg,
      gif: catGif8,
      text: 'All you need is cat'
    },
  ];

  renderMobile = (projects) => {
    const projectList = projects.map(project => (
      <Link key={project.name} to={`/${project.name}`}>
        <div className="project">
          { project.title ? (
              <h2 className="project-name">{project.title}</h2>
            ) : (
              <h2 className="project-name">{project.name}</h2>
            )
          }
          <img src={project.img} alt={project.name} />
        </div>
      </Link>
    ));

    return (
      <div className="mobile-content">
        {projectList}
      </div>
    );
  }

  hover = (e) => {
    this.changeActiveProject(e.target.id)
  }

  changeActiveProject = (project) => {
    const activeProject = _.find(this.projects, ['name', project]);

    this.setState({
      activeProject,
    });
  }

  renderDesktopList = (projects) => {
    const projectList = projects.map(project => {
      const active = this.state.activeProject.name === project.name ? 'active' : '';

      return (
        <li key={project.name} className="project-name">
          <Link to={`/${project.name}`} id={project.name} className={active} onMouseEnter={this.hover}>
            { project.title ? (
                project.title
              ) : (
                project.name
              )
            }
          </Link>
        </li>
      );
    });

    return (
      <ul className="list">
        {projectList}
      </ul>
    );
  }

  renderDesktopContent = () => (
    <div className="desktop-content">
      {this.renderDesktopList(this.projects)}
      {this.renderGifs(this.projects)}
    </div>
  )

  renderDesktopCatContent = () => (
    <div className="desktop-content">
      {this.renderDesktopList(this.catProjects)}
      {this.renderGifs(this.catProjects)}
    </div>
  )

  renderGifs = (projects) => {
    const gifs = projects.map((project, index) => (
      <div key={index} className={`giffy  ${this.state.activeProject.name === project.name ? 'active-gif' : ''}`}>
        <img className="gif" src={project.gif} alt="gif" />
        <p>{project.text}</p>
      </div>
    ));

    return (
      <div className="gif-wrapper">
        {gifs}
      </div>
    );
  }

  render() {
    return (
      (this.props.catify) ? (
        <div className="wrapper">
          {this.renderMobile(this.catProjects)}
          {this.renderDesktopCatContent()}
        </div>
      ) : (
        <div className="wrapper">
          { this.renderMobile(this.projects) }
          { this.renderDesktopContent() }
        </div>
      )
    );
  }
}

export default Home;