import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import SiteWrapper from './components/SiteWrapper';

const AppRouter = () => (
  <Router>
    <SiteWrapper />
  </Router>
);

export default AppRouter;