import React, { Component } from 'react';
import { Link } from "react-router-dom";

import '../styles/footer.scss';
import arrow from '../assets/arrow.png';
import eyes from '../assets/eyes.png';

class Footer extends Component {
  clickLink = () => {
    document.getElementById("next-link").click();
  }

  render() {
    const location = this.props.location.pathname.substr(1);

    const projectNames = [
      'thinx',
      'wordpress',
      'zola',
      'scottevest',
      'primary',
      'keeps',
      // 'cove',
      'eyeballparty',
      'lettering'
    ];

    const currentIndex = projectNames.indexOf(location);
    const nextProject = projectNames[currentIndex + 1] ? projectNames[currentIndex + 1] : projectNames[0];

    return (
      <footer className="project-footer">
        <Link onTouchStart={this.clickLink} id="next-link" to={`/${nextProject}`}>
          <img className="eyes" src={eyes} alt="eyes"></img>
          <span>See More</span>
          <img className="arrow" src={arrow} alt="arrow"></img>
        </Link>
      </footer>
    );
  }
}

export default Footer;