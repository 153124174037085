import React from 'react';
import { Link } from "react-router-dom";

import '../styles/404.scss';

import mapCat from '../assets/404/mapCat.jpg';

function NoMatch() {
  return (
    <div className="no-match-wrapper">
      <div className="img-wrapper">
        <img src={mapCat} alt="lost"></img>
      </div>
      <div className="copy">
        <h1 className="header">Navigation Cat can’t find that page.</h1>
        <p className="text">Sorry about that.</p>
        <Link to="/">Back to homepage</Link>
      </div>
    </div>
  )
};

export default NoMatch;