import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';

import '../styles/header.scss';
import hamburger from '../assets/hamburger.svg'
import close from '../assets/close.svg'

// Cat stuff
import cat from '../assets/cat/catmoji.png';
import emily from '../assets/cat/emilymoji.png';
import '../styles/tooltip.scss';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileNav: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showMobileNav === true) {
      this.setState({
          showMobileNav: false
      });
    }
  }

  toggleNav = () => {
    this.setState({
      showMobileNav: !this.state.showMobileNav,
    })
  }

  // Needed for weird iOS click
  clickCat = () => {
    document.getElementById("mobile-emoji").click();
  }

  render() {
    return (
      <div className="mainheader">
        <header>
          <Link className="logo" to="/">
            <svg id="pink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 428.85 332.74"><title>pinklogo2019</title><path fill="#ff0097" d="M342.32,107.93q34.44,24.46,34.43,62.51a87.48,87.48,0,0,1-2.41,21.14q-9.06,39.87-50.44,57.08t-97.54,17.22a366.94,366.94,0,0,1-53.16-3.63q-.6,31.41,16.92,44.09T239,319a114.38,114.38,0,0,0,27.78-3.32q13.29-3.31,33.83-10,16.91-6,23-6,6.64,0,6.64,7.85.6,21.15-15.7,44.39t-48.32,39.57q-32,16.31-77.31,16.31-67,0-109.93-33.83T36.1,275.54q0-18.12,4.83-42.28Q56,159,107.07,121.22T231.19,83.47Q307.89,83.47,342.32,107.93ZM184.08,226.62q25.36,0,38.65-23.56t12.69-52.55q-.61-16.9-9.67-16.91-6.65,0-15.7,10.57t-17.82,31.71Q183.47,197,177.43,226A28.2,28.2,0,0,0,184.08,226.62Z" transform="translate(-36.1 -83.47)" /><path fill="#ff0097" d="M375.52,400.9a52.53,52.53,0,1,1,37.06,15.3A50.45,50.45,0,0,1,375.52,400.9Z" transform="translate(-36.1 -83.47)" /></svg>
          </Link>
          <ul className="desktop-nav">
            <li><Link to="/about/">About</Link></li>
            <li><Link to="/">Work</Link></li>
            <li className="tooltip tooltip-east" onClick={this.props.catSwitch}>
              <img className="emoji" src={this.props.catify ? emily : cat} alt="Emily and cat emoji" />
              <span className="tooltip-content">{this.props.catify ? "Make site boring again" : "Click for cats"}</span>
            </li>
          </ul>

          { !this.state.showMobileNav ? (
            <img className="hamburger" onClick={this.toggleNav} src={hamburger} alt="menu"/>
          ) : (
            <img className="hamburger" onClick={this.toggleNav} src={close} alt="menu" />
          )}

        </header>
        <nav className={this.state.showMobileNav ? 'active' : ''}>
          <ul>
            <li><Link onClick={this.toggleNav} to="/">Work</Link></li>
            <li><Link onClick={this.toggleNav} to="/about/">About</Link></li>
            <li>
              <img
                src={this.props.catify ? emily : cat}
                id="mobile-emoji"
                className="emoji" alt="Emily emoji"
                onClick={this.props.catSwitch}
              />
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(Header);