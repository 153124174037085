import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

import Footer from './Footer';

import '../styles/rebrand.scss';

import SEVsitemock from '../assets/scottevest/SEVsitemock.png';
import SEVinstagram from '../assets/scottevest/SEVinstagram.jpg';
import instagramMobile from '../assets/scottevest/instagramMobile.jpg';
import SEVbanner1 from '../assets/scottevest/SEVbanner1.png';
import SEVbanner2 from '../assets/scottevest/SEVbanner2.jpg';
import SEVbanner3 from '../assets/scottevest/SEVbanner3.jpg';
import SEVemail1 from '../assets/scottevest/SEVemail1.png';
import SEVemail2 from '../assets/scottevest/SEVemail2.png';
import SEVemail3 from '../assets/scottevest/SEVemail3.png';

// Cats
import cat1 from '../assets/scottevest/cats/instaCat.jpg';
import cat2 from '../assets/scottevest/cats/webCat.png';

class VideoLayout extends Component {
  setHTML = (copy) => {
    return { __html: copy };
  }

  renderCatContent = () => {
    const catContent = {
      header: "meow",
      role: "Purr purr",
      description: "Hello hoo-man will you pet me please yes right there yes good ye- NO I HATE IT STOP OR I’LL END YOU.",
      videos: [
        "https://www.youtube.com/embed/o_igMpbnkWU",
        "https://www.youtube.com/embed/Yjj5RsgpPwA?t=27"
      ]
    }

    return (
      <div className="rebrand-page-content">
        <div className="copy">
          <h1 className="header">{catContent.header}</h1>
          <h2 className="role">{catContent.role}</h2>
          <p className="text">{catContent.description}</p>
        </div>

        <div className="videos-wrapper">
          <div className="video-wrapper">
            <iframe title="vimeo" className="video" src={catContent.videos[0]} allowFullScreen></iframe>
          </div>
        </div>

        <div className="section1">
          <LazyLoad offset={200}>
            <img src={cat1} alt="scottevest"></img>
          </LazyLoad>

          <LazyLoad offset={200}>
            <img src={cat2} alt="scottevest"></img>
            <img src={cat2} alt="scottevest"></img>
          </LazyLoad>
        </div>

        <div className="videos-wrapper-2">
          <div className="video-wrapper">
            <iframe title="vimeo" className="video" src={catContent.videos[1]} allowFullScreen></iframe>
          </div>
        </div>
      </div>
    )
  }

  renderContent = () => {
    const location = this.props.location.pathname.toLowerCase().substr(1);

    const content = {
      scottevest: {
        header: "SCOTTeVEST Rebrand",
        role: "Lead on brand strategy, concepting, and copywriting",
        descriptions: [
          "A rebrand of apparel company SCOTTeVEST, led by the insight that clothing should do more than just clothe you.",
          "For 18 years, SCOTTeVEST has made revolutionary clothing that makes life easier. But because of the heavy focus on their many pockets, they only attracted a niche customer base. We recognized that there’s more to SCOTTeVEST than just pockets, like engineered all-weather fabrics. <br/> <br/>We refreshed their brand strategy from the ground up- including a new look, a new positioning statement, and an adventure-first outlook- to take SCOTTeVEST from a tech-focused niche to a forward-thinking apparel brand for everyone.",
          "We also designed a holiday campaign featuring an interactive guide to help you choose the perfect gift for the adventurer in your life."
        ],
        videos: [
          "https://player.vimeo.com/video/305146132?title=0&byline=0&portrait=0",
          "https://player.vimeo.com/video/306598121?title=0&byline=0&portrait=0",
        ]
      },
    };

    return (
      <div className="rebrand-page-content">
        <div className="copy">
          <h1 className="header">{content[location].header}</h1>
          <h2 className="role">{content[location].role}</h2>
          <p className="text">{content[location].descriptions[0]}</p>
        </div>

        <div className="videos-wrapper">
          <div className="video-wrapper">
            <iframe title="vimeo" className="video" src={content[location].videos[0]} allowFullScreen></iframe>
          </div>
        </div>

        <div className="copy2">
          <p className="text" dangerouslySetInnerHTML={this.setHTML(content[location].descriptions[1])}/>
        </div>

        <div className="section1">
          <LazyLoad offset={200}>
            <img src={SEVsitemock} alt="scottevest"></img>
          </LazyLoad>

          <LazyLoad offset={200}>
            <img src={SEVinstagram} alt="scottevest"></img>
            <img src={instagramMobile} alt="scottevest"></img>
          </LazyLoad>
        </div>

        <div className="section2">
          <LazyLoad offset={200}>
            <img src={SEVbanner1} alt="SCOTTeVEST banner ad"/>
          </LazyLoad>
          <LazyLoad offset={200}>
            <img src={SEVbanner3} alt="SCOTTeVEST banner ad"/>
          </LazyLoad>
          <LazyLoad offset={200}>
            <img src={SEVbanner2} alt="SCOTTeVEST banner ad"/>
          </LazyLoad>
        </div>

        <div className="section3">
          <LazyLoad offset={200}>
            <img src={SEVemail1} alt="SCOTTeVEST email"/>
          </LazyLoad>

          <LazyLoad offset={200}>
            <img src={SEVemail2} alt="SCOTTeVEST email" />
          </LazyLoad>

          <LazyLoad offset={200}>
            <img src={SEVemail3} alt="SCOTTeVEST email" />
          </LazyLoad>
        </div>

        <div className="copy3">
          <p className="text">{content[location].descriptions[2]}</p>
        </div>

        <div className="videos-wrapper-2">
          <div className="video-wrapper">
            <iframe title="vimeo" className="video" src={content[location].videos[1]} allowFullScreen></iframe>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="video-page-wrapper">
        {(this.props.catify) ? (
          this.renderCatContent()
        ) : (
          this.renderContent()
        )}
        <Footer location={this.props.location}/>
      </div>
    );
  }
}

export default VideoLayout;