import React, { Component } from 'react';
import '../styles/cat.scss';

class Cat extends Component {
  transition = () => {
    const catWrapper = document.getElementById("cat-wrapper");
    catWrapper.classList.add("active");

    const body = document.getElementsByTagName("body")[0];
    body.classList.add("noScroll");

    const html = document.getElementsByTagName("html")[0];
    html.classList.add("noScroll");

    setTimeout(() => {
      catWrapper.classList.remove("active");
      body.classList.remove("noScroll");
      html.classList.remove("noScroll");
    }, 4000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.catify === false && this.props.catify === true) {
      this.transition();
    }
  }

  render() {
    return (
      <div id="cat-wrapper">
        <div className="cat">
          <div className="ear ear--left"></div>
          <div className="ear ear--right"></div>
          <div className="face">
            <div className="eye eye--left">
              <div className="eye-pupil"></div>
            </div>
            <div className="eye eye--right">
              <div className="eye-pupil"></div>
            </div>
            <div className="muzzle"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cat;