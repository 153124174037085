import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

import Footer from './Footer';

import '../styles/grid.scss';

// Lettering Images
import ipad from '../assets/grid/lettering/ipad.png';
import flowers from '../assets/grid/lettering/flowers.jpg';
import donttouch from '../assets/grid/lettering/donttouch.jpg';
import ass from '../assets/grid/lettering/ass.jpg';
import girls from '../assets/grid/lettering/girls.jpg';
import deadplants from '../assets/grid/lettering/deadplants.jpg';
import wine from '../assets/grid/lettering/wine.jpg';
import health from '../assets/grid/lettering/health.jpg';
import farting from '../assets/grid/lettering/farting.jpg';
import sheworks from '../assets/grid/lettering/sheworks.jpg';
import kombucha from '../assets/grid/lettering/kombucha.gif';
import instamock from '../assets/grid/lettering/instamock.png';
import instascreenLetter from '../assets/grid/lettering/instascreen.jpg';

// Eyeball Party Images
import gothic from '../assets/grid/eyeballparty/gothic.jpg';
import pearl from '../assets/grid/eyeballparty/pearl.jpg';
import klimt from '../assets/grid/eyeballparty/klimt.jpg';
import monalisa from '../assets/grid/eyeballparty/monalisa.jpg';
import scream from '../assets/grid/eyeballparty/scream.jpg';
import medusa from '../assets/grid/eyeballparty/medusa.jpg';
import nighthawks from '../assets/grid/eyeballparty/nighthawks.jpg';
import dance from '../assets/grid/eyeballparty/dance.jpg';
import judith from '../assets/grid/eyeballparty/judith.jpg';
import lovers from '../assets/grid/eyeballparty/lovers.jpg';
import drown from '../assets/grid/eyeballparty/drown.jpg';
import instaEyeball from '../assets/grid/eyeballparty/instaeyeball.png';
import instascreenEye from '../assets/grid/eyeballparty/instascreen.jpg';

// Cat Eyeball Images
import EPheader from '../assets/grid/eyeballparty/cat/EPheader.jpg';
import EP1 from '../assets/grid/eyeballparty/cat/EP1.jpg';
import EP2 from '../assets/grid/eyeballparty/cat/EP2.jpg';
import EP3 from '../assets/grid/eyeballparty/cat/EP3.jpg';
import EP4 from '../assets/grid/eyeballparty/cat/EP4.jpg';
import EP5 from '../assets/grid/eyeballparty/cat/EP5.jpg';
import EP6 from '../assets/grid/eyeballparty/cat/EP6.jpg';

// Cat Lettering Images
import letterCatHeader from '../assets/grid/lettering/cat/header.jpg';
import letterCat1 from '../assets/grid/lettering/cat/letterCat1.jpg';
import letterCat2 from '../assets/grid/lettering/cat/letterCat2.jpg';
import letterCat3 from '../assets/grid/lettering/cat/letterCat3.jpg';
import letterCat4 from '../assets/grid/lettering/cat/letterCat4.jpg';
import letterCat5 from '../assets/grid/lettering/cat/letterCat5.jpg';
import letterCat6 from '../assets/grid/lettering/cat/letterCat6.jpg';

class GridLayout extends Component {
  constructor(props) {
    super(props);

    const location = this.props.location.pathname.toLowerCase().substr(1);

    this.state = {
      content: this.content[location]
    }
  }

  content = {
    lettering: {
      header: 'Letter Like a Lady',
      role: 'Illustration and writing',
      __html: "<p>A passion project turned Instagram account, <a href='https://www.instagram.com/letterlikealady/' target='_blank'>Letter Like a Lady</a> is a public diary of sorts, complete with doodles, flourishes, and puns.</p>",
      headerImg: ipad,
      images: [
        flowers,
        donttouch,
        ass,
        girls,
        instamock,
        instascreenLetter,
        deadplants,
        kombucha,
        health,
        farting,
        sheworks,
        wine,
      ]
    },
    eyeballparty: {
      header: 'Eyeball Party',
      role: 'Supervision of googly eye placement',
      __html: "<p>I like googly eyes. I like art. <a href='https://www.instagram.com/eyeballparty/' target='_blank'>Eyeball Party</a> is the best of both worlds. Plus, each post features a juicy art history hot take, like Michelangelo was one stinky dude. Who knew?</p>",
      headerImg: gothic,
      images: [
        pearl,
        klimt,
        monalisa,
        scream,
        instaEyeball,
        instascreenEye,
        medusa,
        nighthawks,
        dance,
        judith,
        lovers,
        drown
      ]
    },
  };

  componentDidMount() {
    const location = this.props.location.pathname.toLowerCase().substr(1);

    this.setState({
      content: this.content[location]
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const location = this.props.location.pathname.toLowerCase().substr(1);

      this.setState({
        content: this.content[location]
      });
    }
  }

  renderImages = (imageArr) => {
    const images = imageArr.map((imageSrc, index) => (
      <LazyLoad key={index} offset={200}>
        <img src={imageSrc} alt="Lettering" />
      </LazyLoad>
    ));

    return images;
  };

  renderCatImages = (imageArr) => {
    const images = imageArr.map((imageSrc, index) => (
      <img key={index} src={imageSrc} alt="Lettering" />
    ));

    return images;
  };

  renderCatContent = () => {
    const location = this.props.location.pathname.toLowerCase().substr(1);

    const catContent = {
      eyeballparty: {
        header: 'meow?',
        role: 'meowororororoew',
        description: "👀👀👀",
        headerImg: EPheader,
        images: [
          EP1,
          EP2,
          EP3,
          EP4,
          EP5,
          EP6,
        ]
      },
      lettering: {
        header: 'meowreow',
        role: 'Meow meow meow purr meow',
        description: "Floofy feline friends",
        headerImg: letterCatHeader,
        images: [
          letterCat1,
          letterCat2,
          letterCat3,
          letterCat4,
          letterCat5,
          letterCat6
        ]
      },
    };

    return (
      <div className="grid-page-content">
        <div className="copy">
          <h1 className="header">{catContent[location].header}</h1>
          <h2 className="role">{catContent[location].role}</h2>
          <div className="text">{catContent[location].description}</div>
        </div>

        <div className="header-image-wrapper">
          <img src={catContent[location].headerImg} alt="ipad" />
        </div>

        <div className="image-grid cat-grid">
          {this.renderImages(catContent[location].images)}
        </div>
      </div>
    )
  }

  renderContent = () => {
    return (
      <div className="grid-page-content">
        <div className="copy">
          <h1 className="header">{this.state.content.header}</h1>
          <h2 className="role">{this.state.content.role}</h2>
          <div className="text" dangerouslySetInnerHTML={this.state.content} />
        </div>

        <div className="header-image-wrapper">
          <img src={this.state.content.headerImg} alt="ipad"/>
        </div>

        <div className="image-grid">
          {
            !!this.state.content.images && !!this.state.content.images.length &&
            this.renderImages(this.state.content.images)
          }
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {(!!this.props.catify) ? (
          this.renderCatContent()
        ) : (
          this.renderContent()
        )}
        <Footer location={this.props.location} />
      </div>
    );
  }
}

export default GridLayout;