import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from "react-router-dom";

// Load all components
import Loading from './Loading';
import Header from './Header';
import About from './About';
import VideoLayout from './VideoLayout';
import RebrandLayout from './RebrandLayout';
import GridLayout from './GridLayout';
import NoMatch from './NoMatch';
import Cat from './Cat';

class SiteWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catify: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  catSwitch = () => {
    this.setState({
      catify: !this.state.catify
    });
  }

  render() {
    return (
      <div>
        <Cat catify={this.state.catify} />
        <Header catSwitch={this.catSwitch} catify={this.state.catify}/>
        <Switch>
          <Route path="/" exact render={props => <Loading {...props} catify={this.state.catify} />} />
          <Route path="/about" render={props => <About {...props} catify={this.state.catify}/>} />
          <Route path="/(thinx|wordpress|zola|primary|keeps|cove)/" render={props => <VideoLayout {...props} catify={this.state.catify} />} />
          <Route path="/scottevest" render={props => <RebrandLayout {...props} catify={this.state.catify}  />} />
          <Route path="/(lettering|eyeballparty)/" render={props => <GridLayout {...props} catify={this.state.catify} />} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(SiteWrapper);